@charset "UTF-8";

@import "./media-queries";

*,
*::before,
*::after {
  box-sizing: border-box;
}

$color-dark: #191919;
$color-white: #ffffff;

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include sm {
    margin-top: 48px;
  }
}

body {
  font-family: "Inter Variable", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  color: $color-dark;
}

h1 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.4rem;
  padding: 0;
  margin: 0;
}

.footer {
  font-size: 1.2em;
  margin-top: 40px;
  padding-top: 40px;

  width: 100vw;
  min-height: 80vh;

  background-color: #292929;
  color: #efefef;

  a {
    color: rgb(235, 142, 250);
    text-decoration: none;
  }

  .credit {
    margin-top: 48px;
  }

  .banner {
    @include sm {
      width: 100%;
    }
  }
}

.selects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
}

button {
  margin-top: 20px;

  appearance: button;
  backface-visibility: hidden;
  background-color: $color-dark;
  border-radius: 6px;
  border-width: 0;
  //box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
  //  rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;

  &:hover {
    background-color: rgb(176, 0, 192);
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.result-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result {
  margin-top: 40px;
  background-color: rgb(252, 240, 254);
  padding: 30px;
  border: 4px solid rgb(241, 216, 245);
  border-radius: 20px;
  width: 60%;
  font-size: 2em;
  line-height: 140%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  @include sm {
    font-size: 1.2em;
    width: 80%;
  }
}

.result-caption {
  margin-top: 12px;

  @include sm {
    width: 80%;
  }
}

.copy-icon {
  background-color: rgb(241, 216, 245);
  border-radius: 12px;
  display: grid;
  place-items: center;
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.copy-icon img {
  width: 60px;
  height: 60px;
}

select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: $color-white;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: $color-dark;
  cursor: pointer;
}

input {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 200px;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: $color-white;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: $color-dark;
  cursor: pointer;
}

.select-wrapper {
  margin: 0;
}

.select {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1.15rem;
  padding: 0.675em 0.675em 0.675em 1em;
  background-color: $color-white;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: $color-dark;
  cursor: pointer;

  &:hover {
    background-color: rgb(252, 240, 254);
    border: 1px solid rgb(241, 216, 245);
  }

  z-index: 10;
}

.arrow-down {
  margin-left: 0.675em;
  display: grid;
  place-items: center;
}

.arrow-down img {
  width: 20px;
  height: 20px;
}

/* Styles for the dropdown content */
.dropdown-content {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: $color-white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Styles for individual dropdown items */
.dropdown-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 10;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  z-index: 10;
}

/* Styles for group labels */
.group-label {
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-color: #f9f9f9;
  z-index: 10;
}

.styles-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  justify-content: center;
}

.style-button {
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 12px;
  color: $color-dark;
  width: 200px;
  height: 120px;
  font-weight: 500;
  font-size: 1.2rem;

  &:hover {
    color: $color-white;
  }

  @include sm {
    width: 80%;
    height: 40px;
  }
}

.style-selected {
  background-color: $color-dark;
  color: $color-white;
  border: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 50px;
  border: 2px solid rgb(255, 182, 229);
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

/* Simple fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
